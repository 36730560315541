import React from "react";
import NavBar from "../components/navigation/NavBar";
import ImageComp from "../components/Image";
// import CircledText from "../components/CircledText";
// import GradientText from "../components/GradientText";
// import Accordion from "../components/Accordion";
// import BlogCardComponent from "../components/BlogCard";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
// import ServiceCardComponent from "../components/ServiceCard";
import MobileNav from "../components/navigation/MobileNav";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Marquee from "../components/Marque";
import Founder from "../components/Founder";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";


const Card = ({ count, title, bg1, bg2, path, list }) => {
  return (
    <div to={path} className="animated-card w-fit">
      <div
        className={`relative z-30 w-[380px] sm:w-[340px] h-[300px]  xl:w-[280px] xl:h-[300px] 2xl:w-[350px] 2xl:h-[350px] ${bg1} rounded-3xl overflow-hidden bg-cover bg-center transition-all ease-in-out duration-100 animated-card-child z-20`}
      >
        <div className="absolute bottom-0 left-0 w-full p-4 test">
          <div className="mb-10 text-container">
            <div className="text-white font-extrabold text-3xl 2xl:text-app-50 mb-2 font-roboto z-10">
              {count}
            </div>
            <div className="bg-white w-9 2xl:w-[56px] h-1 mb-2"></div>
            <div className="text-white text-sm md:text-base 2xl:text-app-22 font-extrabold uppercase font-roboto max-w-[153px]">
              {title}
            </div>

            <ul className="mx-3 mt-2 list">
              {list.map((item, i) => <li className="text-xs list-disc font-medium text-white" key={i}>{item}</li>)}

            </ul>
          </div>
          <div className="absolute bottom-[-1.5rem] right-[-1.5rem] cursor-pointer group">
            <div className="relative w-[100px] h-[100px] bg-app-purple rounded-full flex items-center justify-center group-hover:bg-white transition-all ease-in-out duration-100 animated-arrow pb-3 pl-4">

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const offers = [
    {
      count: "01",
      title: "Incubation Program",
      bg1: "bg-bg-offer-1",
      bg2: "group-hover:bg-bg-offer-1-variant",
      path: "/services/artificial-intelligence",
      list: ['Web3 Strategic consulting', "Market Entry strategy", 'Mentorship', 'Resource Provision', '“Innovate Now, Pay Later” Program']
    },
    {
      count: "02",
      title: "WEB 3.0 DEVELOPMENT",
      bg1: "bg-bg-offer-2",
      bg2: "group-hover:bg-bg-offer-2-variant",
      path: "/services/web3-development",
      list: ['UX/UI design', "Prototype/MVP development", "DApp Creation", 'Smart Contract Development', 'Tokenomics', 'Web3 Integration Strategy']
    },
    {
      count: "03",
      title: "Branding",
      bg1: "bg-bg-offer-3",
      bg2: "group-hover:bg-bg-offer-3-variant",
      path: "/services/software-development",
      list: ['Pitch Deck Design', 'Whitepaper Creation', 'Web3 Identity Design', 'Brand Identity Design']
    },
    {
      count: "04",
      title: "CREATIVE AND MULTIMEDIA DESIGN",
      bg1: "bg-bg-offer-4",
      bg2: "group-hover:bg-bg-offer-4-variant",
      path: "/services/creative-design", list: ['2D and 3D design', 'Animation', 'Cinematic Trailers', 'Mascot Design']
    },
    {
      count: "05",
      title: "Marketing",
      bg1: "bg-bg-offer-5",
      bg2: "group-hover:bg-bg-offer-5-variant",
      path: "/services/strategic-advisory",
      list: ['Marketing strategy development', 'Promotional campaigns and TGEs', 'Influencer partnerships', 'community engagement', 'Social media management']
    },
    {
      count: "06",
      title: "GAME DEVELOPEMENT",
      bg1: "bg-bg-offer-6",
      bg2: "group-hover:bg-bg-offer-6-variant",
      path: "/services/game-development", list: ['TON Game', 'Unreal Engine 5 ', 'Unity', 'Game level design']
    },
    {
      count: "07",
      title: "Audits",
      bg1: "bg-bg-offer-7",
      bg2: "group-hover:bg-bg-offer-7-variant",
      path: "/services/lead-ambassador",
      list: ['Penetration Testing', 'Token Compliance Review', 'Smart Contract Performance Audit', 'Security Assessment']
    },
    {
      count: "08",
      title: "Networking",
      bg1: "bg-bg-offer-8",
      bg2: "group-hover:bg-bg-offer-8-variant",
      list: ['Exchange Introduction', 'Grant Application', 'Launchpad Introduction', 'Strategic Partnerships', 'Blockchain Events & Conferences', 'Fundraising']
    },
  ];

  const texts = [
    "INCUBATION",
    "NETWORKING",
    "MARKETING",
    "INVESTMENT",
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Sky Ventures Lab</title>
        <link rel="canonical" href="https://www.skyventurelab.com/" />
      </Helmet>
      <section className="w-full bg-hero-section bg-cover lg:bg-contain bg-center  xl:bg-cover bg-no-repeat h-screen relative pt-[4rem]">
        {/* Adjusted padding-top */}

        {/* Navbar */}
        <div className="hidden md:absolute lg:block top-0 left-0 right-0">
          <NavBar />
        </div>
        <div className="fixed z-50 lg:hidden top-[0rem] left-0 right-0 bg-white py-4">
          <MobileNav />
        </div>

        {/* Main Content */}
        <div className="flex flex-col items-center justify-center h-full pb-10">

          <div>
            <h2 className="text-5xl lg:text-6xl 2xl:text-app-100 font-bold text-center uppercase  text-white ">
              One world, one ecosystem
            </h2>
            <h2 className="text-5xl lg:text-6xl 2xl:text-app-100 font-bold text-center uppercase mb-6 text-white">
              #1 incubator
            </h2>
          </div>
          <p className="text-center font-medium text-lg md:text-2xl  2xl:text-app-3xl w-full 2xl:max-w-[1268px] max-w-[800px] flex justify-center mb-12 2xl:mb-[100px] text-white">
            Risk-Sharing Incubation: We are the first to invest in your success by sharing the risks, not just the rewards
          </p>

        </div>
      </section>
      <Marquee texts={texts} />

      <div>
        {/* section2 */}


        <div className="px-4 md:px-0  w-full white flex-col flex gap-y-6 items-center 
            md:h-[770px] py-10 md:py-24">
          <div className="relative w-full ">

            <ImageComp image="/images/shy-ventures-text.svg" styles="object-contain " />
            <div className="absolute bottom-2 w-full flex items-center justify-center ">

              <h2 className=" text-2xl md:text-app-80 lg:text-app-100 text-center font-bold bg-text-gradient inline-block text-transparent bg-clip-text">GET TO KNOW US</h2>
            </div>
          </div>
          <div className=" mx-auto xl:max-w-[90%]">

            <p className="font-bold text-lg md:text-[44px] xl:text-[64px] text-black text-center md:leading-[79px] capitalize">A leading incubator shaping the Web3 ecosystem through strategic growth, innovative incubation, and global connectivity.
            </p>
          </div>
        </div>


        <div className="py-16  2xl:pt-[173px] flex justify-center ">
          <div className="">
            <div className="  bg-white h-[600px] bg-about-section-2-bg lg:bg-contain bg-center bg-no-repeat xl:bg-cover flex items-center xl:px-24">
              <h6 className="font-bold text-2xl md:text-[54px] xl:text-[64px]  text-center md:leading-[93px] text-white capitalize"> We make the journey from idea to impact not just possible, but also predictable.</h6>
            </div>

            <div className="w-full  mx-auto px-4 md:px-0 pb-[35px] 2xl:[106px]">
              <div className="relative  ">
                <div className="md:w-[90%] mx-auto">

                  <ImageComp image="images/offers.svg" />
                </div>
                <div className="absolute bottom-14 md:bottom-24 w-full flex items-center justify-end  ">

                  <h2 className="text-2xl  md:text-app-80 lg:text-app-80 text-right font-bold bg-text-gradient inline-block text-transparent bg-clip-text">OUR INCUBATION PROGRAM</h2>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 justify-center gap-8 md:w-[90%] mx-auto">
                {offers?.map((item, index) => (
                  <Card
                    key={index}
                    title={item?.title}
                    count={item?.count}
                    bg1={item?.bg1}
                    bg2={item?.bg2}
                    path={item?.path}
                    list={item.list}
                  />
                ))}
              </div>
            </div>
            <div className="px-4 md:px-0 w-full md:w-[90%] mx-auto pt-[50px] md:pt-[100px]  ">
              <ImageComp image="images/portfolio-text.svg" />
              <div className="grid grid-cols-1 lg:grid-cols-2 mb-16 2xl:mb-[160px] gap-[62px] 2xl:gap-x-24">
                <div className="flex items-center justify-center lg:justify-start">
                  <h1 className="font-bold text-2xl text-center md:text-6xl 2xl:text-app-100 uppercase text-[#6F00FF]">
                    NOTABLE PORTFOLIO
                  </h1>
                </div>
                <ImageComp
                  image="images/hero-section-3.png"
                  styles="col-span-2 xl:col-span-1"
                />
              </div>
            </div>
            <div className="py-[100px] bg-black ">
              <div className="flex justify-center bg-partners-background
                bg-contain   bg-center bg-no-repeat">
                <div>
                  <div className="mx-auto">
                    <div className="relative w-full h-32 flex items-center justify-center mb-3.5">
                      <div className="absolute inset-0 flex flex-col items-center justify-center gap-y-3 ">

                        <div className="space-x-2 top-left-animated ">
                          <span className="inline-block w-[16.06px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>
                          <span className="inline-block w-[35.32px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>
                          <span className="inline-block w-[107.06px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>

                        </div>
                        <span className="text-2xl md:text-3xl xl:text-7xl  text-white font-bold uppercase tracking-wider  lg:self-end">
                          Ecosystem Partners
                        </span>
                        <div className="space-x-2 top-right-animated ">
                          <span className="inline-block w-[107.06px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>
                          <span className="inline-block w-[35.32px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>

                          <span className="inline-block w-[16.06px] h-[11.24px] bg-[#6F00FF] rounded-md"></span>


                        </div>
                      </div>
                      {/* <div className="relative">
                        <div className="text-white font-bold text-4xl relative">
                          <span className="relative z-10 text-2xl md:text-6xl 2xl:text-app-100 uppercase">
                            Our Partners
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="mx-auto md:w-4/5 w-full md:mt-10">
                    <ImageComp image="/images/logos.png" />
                  </div>
                </div>
              </div>
            </div>


            <section className="w-full px-4 md:px-0  mb-20 md:my-[100px] ">
              <div className="w-full md:w-4/5 mx-auto">
                <div className="flex flex-col items-center justify-center md:gap-6 my-16 md:mb-[50px]">
                  <p className="text-left md:text-center font-bold text-2xl md:text-7xl 2xl:text-app-100 text-app-purple uppercase">
                    THE FOUNDERS
                  </p>
                  <p className="w-full lg:w-3/4 text-center font-medium text-sm md:text-2xl 2xl:text-app-32">
                    We are dedicated to nurturing ambitious projects from inception to launch, ensuring projects not only survive but excel.
                  </p>
                </div>

                <div className="flex flex-col md:flex-row justify-center items-center sm:gap-x-24 lg:gap-x-64 bg-founder-section  bg-contain  bg-no-repeat  py-24">



                  <Founder
                    imagepath={"/images/meet-forbes.svg"}
                    name={"Sky Wee"}
                    post={"CO-FOUNDER | MD | CBDO"}
                    description={<>Crypto & Gaming Influencer with 4M followers | Forbes Business Council Member | Managing Partner at VC firms | Binance Official KOL |  <Link className="text-blue-500" to='
https://councils.forbes.com/profile/Sky-Wee-Managing-Partner-VC-Crypto-Influencer-Advisor-ATF-Capital/950ed4a7-2f34-4b69-a936-cf301062f115'>
                      https://councils.forbes.com/profile/Sky-Wee-Managing-Partner-VC-Crypto-Influencer-Advisor-ATF-Capital/950ed4a7-2f34-4b69-a936-cf301062f115</Link></>}
                    social={[
                      { icon: <FaFacebookF className='w-4 h-4 ' />, link: 'https://www.facebook.com/skywee97' },
                      { icon: <BsTwitterX className='w-4 h-4 ' />, link: 'https://x.com/OfficialSkyWee1' },

                      { icon: <FaLinkedinIn className='w-4 h-4 ' />, link: 'https://www.linkedin.com/in/skywee97/' },
                      { icon: <FaTiktok className='w-4 h-4 ' />, link: 'https://www.tiktok.com/@skywee97_' },
                      { icon: <FaInstagram className='w-4 h-4 ' />, link: 'https://www.instagram.com/skywee97/' },

                    ]}


                  />
                  <Founder
                    imagepath={"/images/allen.svg"}
                    name={"Allen Tan Chee Hoe"}
                    post={"CO-FOUNDER | VP | CIO"}
                    description=' 15 years+ of experience in product development, focusing on crafting solutions for underserved communities. Ex Findwork that acquiring over 4 million certified and verified users and connecting with more than 7,000 businesses in the blue-collar sector.'
                    social={[

                      {
                        icon: <BsTwitterX className='w-4 h-4 ' />, link: 'https://x.com/AllenTanCheeHoe'
                      },

                      { icon: <FaLinkedinIn className='w-4 h-4 ' />, link: 'https://www.linkedin.com/in/allen-tan-chee-hoe/' },

                    ]}

                  />

                </div>
              </div>
            </section>



            <section className="mb-10 w-full bg-svlab-section lg:bg-contain bg-cover bg-center xl:bg-cover bg-no-repeat lg:h-screen relative pt-[4rem]">
              {/* Adjusted padding-top */}

              {/* Main Content */}
              <div className="flex flex-col items-center  lg:justify-end h-full pb-10">

                <div>
                  <h2 className=" opacity-70 text-3xl md:text-5xl lg:text-6xl 2xl:text-app-100 font-bold text-center uppercase  text-white mb-6">
                    SKY VENTURE LABS
                  </h2>
                </div>
                <p className="text-center uppercase text-lg md:text-3xl  2xl:text-app-3xl font-bold w-full 2xl:max-w-[1268px] max-w-[800px] flex justify-center mb-12 2xl:mb-[100px] text-white ">
                  One World, One Ecosystem," encapsulates our commitment to creating a unified Incubation platform for global innovation.          </p>

              </div>
            </section>



            <Contact />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
