import { Route, Routes, useLocation } from "react-router";
import { Helmet } from "react-helmet";
import { AnimatePresence } from "framer-motion";
import { Suspense } from "react";
import Home from "./pages/Home";
// import About from "./pages/About";
// import WhyChooseUs from "./pages/WhyChooseUs";
// import ContactUs from "./pages/ContactUs";
// import ComingSoon from "./pages/ComingSoon";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy";
// import ArtificialIntelligence from "./pages/services/ArtificialIntelligence";
// import WebDevelopment from "./pages/services/WebDevelopment";
// import GameDevelopment from "./pages/services/GameDevelopment";
// import CreativeDesign from "./pages/services/CreativeDesign";
//import LeadAmbassador from "./pages/services/LeadAmbassador";
//import StrategicAdvisory from "./pages/services/StrategicAdvisory";
// import SoftwareDevelopment from "./pages/services/SoftwareDevelopment";

function App() {
  const location = useLocation();

  return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>sky Ventures Lab</title>
				<link rel="canonical" href="https://www.skyventures.xyz/" />
			</Helmet>
			<section>
				<main className="text-app-white mx-auto body-size overflow-hidden">
					<Suspense fallback={<p>Loading...</p>}>
						<AnimatePresence mode="wait">
							<Routes location={location} key={location.key}>
								<Route path="/" element={<Home />} />

								<Route
									path="/terms-and-conditions"
									element={<TermsAndCondition />}
								/>
								<Route
									path="/privacy-and-policy"
									element={<PrivacyAndPolicy />}
								/>
								<Route path="*" element={<Home />} />
							</Routes>
						</AnimatePresence>
					</Suspense>
				</main>
			</section>
		</>
	);
}

export default App;
